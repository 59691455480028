import "../styles/CreateActivity.css";

import { vehicleTypes, extras, vehifeatures } from "../data";

import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IoIosImages } from "react-icons/io";
import { useState, useEffect, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import "../styles/CreateRestaurant.css";
import rentimg from "../images/rentcar.png"
import app from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import Navbar2 from "../components/Navbar2";
import Swal from "sweetalert2";

export const CreateVehicleRent = () => {
    const { listingId } = useParams(); // Get the listingId from params
  const [type, setType] = useState("");
  const [category] = useState("Vehicle Rent");
  const [formLocation, setFormLocation] = useState({
    streetAddress: "",
    city: "",
    province: "",
  });
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [contactNumber, setContactNumber] = useState("+94");
  const [airConditioning, setAirConditioning] = useState("");
  const [gearType, setGearType] = useState("");
  const [driver, setDriver] = useState("");
  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormLocation({
      ...formLocation,
      [name]: value,
    });
  };

  const handleChangeContactNumber = (e) => {
    const { value } = e.target;
    if (value.startsWith("+94")) {
        setContactNumber(value);
    } else if (value === "" || value === "+") {
        setContactNumber("+94");
    }
};
 
  const [seatCount, setSeatCount] = useState(0);
  const [doorCount, setDoorCount] = useState(0);
  const [amenities, setAmenities] = useState([]);
  const [features, setFeatures] = useState([]);
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]); // New state to keep track of uploaded images
  const [imgPerc, setImgPerc] = useState("");
  const [inputs, setInputs] = useState({});
  const [imageUploaded, setImageUploaded] = useState(false);

  const handleSelectAmenities = (facility) => {
    if (amenities.includes(facility)) {
      setAmenities((prevAmenities) =>
        prevAmenities.filter((option) => option !== facility)
      );
    } else {
      setAmenities((prev) => [...prev, facility]);
    }
  };

  const handleSelectFeatures = (vfeature) => {
    if (features.includes(vfeature)) {
      setFeatures((prevFeatures) =>
        prevFeatures.filter((option) => option !== vfeature)
      );
    } else {
      setFeatures((prev) => [...prev, vfeature]);
    }
  };


  const [formDescription, setFormDescription] = useState({
    hoteltitle:"",
    title: "",
    description: "",
    model: "",
    mileage:"",
    extraCharge:"",
    minimumPeriod:"",
    maximumPeriod:"",
    price: "",
  });

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setFormDescription({
      ...formDescription,
      [name]: value,
    });
  };

  const creatorId = useSelector((state) => state.user._id);
  const navigate = useNavigate();

  // Fetch the listing details if listingId exists
  useEffect(() => {
    if (listingId) {
      const fetchListingDetails = async () => {
        try {
          const response = await fetch(`http://localhost:8000/activities/${listingId}`, {
            method: "GET",
          });
          const data = await response.json();
          // Populate form fields with the fetched data
          setType(data.type);
          setFormLocation({
            streetAddress: data.streetAddress,
            city: data.city,
            province: data.province,
          });
          setSeatCount(data.seatCount);
          setDoorCount(data.doorCount);
          setAmenities(data.amenities);
          setFeatures(data.features);
          setFormDescription({
            title: data.title,
            description: data.description,
            model: data.model,
            price: data.price,
            extraCharge: data.extraCharge,
            mileage: data.mileage,
            minimumPeriod: data.minimumPeriod,
            maximumPeriod: data.maximumPeriod,

          });
          setContactNumber(data.contactNumber)
          setAirConditioning(data.airConditioning)
          setGearType(data.gearType)
          setDriver(data.driver)
          
        } catch (err) {
          console.error("Fetch Listing Details Failed", err.message);
        }
      };
      fetchListingDetails();
    }
  }, [listingId]);

  // Use a ref to keep track of the files being uploaded to avoid re-uploading the same files
  const imagesRef = useRef([]);

  useEffect(() => {
    if (images.length > 0) {
      const newImages = images.filter(image => !imagesRef.current.includes(image));
      newImages.forEach(image => {
        imagesRef.current.push(image);
        uploadFile(image);
      });
    }
  }, [images]);

  const uploadFile = (file) => {
    const storage = getStorage();
    const folder = "images/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImgPerc(Math.round(progress));
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        switch (error.code) {
          case 'storage/unauthorized':
            console.log(error);
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            const updatedImgUrls = prev.imgUrls ? [...prev.imgUrls, downloadURL] : [downloadURL];
            return {
              ...prev,
              imgUrls: updatedImgUrls,
            };
          });
          setUploadedImages((prev) => [...prev, downloadURL]);
        });
      }
    );
  };

  const handlePost = async (e) => {
    e.preventDefault();

    try {
      const listingForm = new FormData();
      listingForm.append("creator", creatorId);
      listingForm.append("category", category);
      listingForm.append("type", type);
      listingForm.append("streetAddress", formLocation.streetAddress);
      listingForm.append("city", formLocation.city);
      listingForm.append("province", formLocation.province);
      listingForm.append("seatCount", seatCount);
      listingForm.append("doorCount", doorCount);
      listingForm.append("amenities", amenities);
      listingForm.append("features", features);
      listingForm.append("description", formDescription.description);
      listingForm.append("model", formDescription.model);
      listingForm.append("price", formDescription.price);
      listingForm.append("extraCharge", formDescription.extraCharge);
      listingForm.append("mileage", formDescription.mileage);
      listingForm.append("minimumPeriod", formDescription.minimumPeriod);
      listingForm.append("maximumPeriod", formDescription.maximumPeriod);
      listingForm.append("contactNumber", contactNumber);
      listingForm.append("airConditioning", airConditioning);
      listingForm.append("gearType", gearType);
      listingForm.append("driver", driver);
      listingForm.append("approve", false);
      inputs.imgUrls.forEach((url, index) => {
        listingForm.append(`imgUrls[${index}]`, url);
      });
      
      

      const response = await fetch(
        listingId ? `http://localhost:8000/activities/${listingId}` : `${API_BASE_URL}/rentvehicle/create/rent-vehicle`,
        {
          method: listingId ? "PUT" : "POST",
          body: listingForm,
        }
      );

      if (response.ok) {
        Swal.fire({
          title: "Your Property has been created Successfully! It is under Review for approval",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            popup: 'swal-custom'
          }
        }).then(() => {
          navigate("/");
        });
      } else {
        console.error("Failed to create/update listing");
      }
    } catch (err) {
      console.error("Publish Listing failed", err.message);
    }
  };


    const handleRemoveImage = (index) => {
      if (!inputs.imgUrls || !inputs.imgUrls[index]) {
        console.error("Image URL not found for index:", index);
        return;
      }
      const storagePath = inputs.imgUrls[index];
      const storage = getStorage();
      const imageRef = ref(storage, storagePath);
  
      deleteObject(imageRef).then(() => {
        console.log('File deleted successfully from Firebase Storage');
        // Update the state to remove the image from the list
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setInputs((prev) => {
          const updatedImgUrls = prev.imgUrls.filter((_, i) => i !== index);
          return { ...prev, imgUrls: updatedImgUrls };
        });
        setUploadedImages((prev) => prev.filter((_, i) => i !== index));
        imagesRef.current = imagesRef.current.filter((_, i) => i !== index);
        if (images.length === 1) setImageUploaded(false);
        setImgPerc("");
      }).catch((error) => {
        console.error('Error deleting file from Firebase Storage:', error);
      });
    };
  
    const handleFileChange = (e) => {
      const newFiles = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...newFiles]);
    };
  
  return (
    <>
        <Navbar2 />
  
        <div className="create-activities">
        <div className="heading">
          <div><h3>PUBLISH YOUR RENT VEHICLES</h3>
          <p>Showcase your culinary delights to the world. Join our platform to reach more customers and grow your business.<span className="res-highlight">All information is required unless marked optional.</span></p></div>
          <img src={rentimg}/>
          </div>
          <form onSubmit={handlePost}>
            <div className="create-activities_step1">
            <div className="step-heading">
            <div className="step-number">STEP:01</div>
           
            <div className="step-description">TELL US ABOUT YOUR VEHICLE RENT</div>
              <hr /></div>
              <div className="description-box">
              <h3>WHAT TYPE OF VEHICLE YOU HAVE?</h3>
              <div className="type-list">
                {vehicleTypes?.map((item, index) => (
                  <div
                    className={`type ${type === item.name ? "selected" : ""}`}
                    key={index}
                    onClick={() => setType(item.name)}
                  >
                    <div className="type_icon">{item.icon}</div>
                    <div className="type_text">
                      <h4>{item.name}</h4>
                    </div>
                    
                  </div>
                ))}
              </div></div>

              <div className="add-location">
            <h3>WHERE IS YOUR PLACE LOCATED?</h3>
            <div className="full">
              <div className="location">
                <p>Street Address</p>
                <input
                  type="text"
                  placeholder="Street Address"
                  name="streetAddress"
                  value={formLocation.streetAddress}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
            </div>
            <div className="half">
              <div className="location">
                <p>Province</p>
                <input
                  type="text"
                  placeholder="Province"
                  name="province"
                  value={formLocation.province}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
              <div className="location">
                <p>City</p>
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formLocation.city}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
            </div></div>


            <div className="add-photos">
              <h3>ADD SOME PHOTOS OF YOUR VEHICLE</h3>
              <div>
              {images.length > 0 && (
                <div className="uploaded-image-container">
                  {images.map((image, index) => (
                    <div className="uploaded-image" key={index}>
                      {imgPerc > 0 && "Uploading: " + imgPerc + "%"}
                      <img src={URL.createObjectURL(image)} alt="Uploaded" width="100" />
                      <button type="button" onClick={() => handleRemoveImage(index)}>
                        <BiTrash style={{ color: 'red', fontSize: '18px' }} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                id="img"
                multiple
                onChange={handleFileChange}
              />
            </div>
            </div>

            <div className="add-photos">
            <h3>ADD YOUR VEHICLE MODEL & DESCRIPTION</h3>
          <div className="description">
          
          <p>Vehicle Model</p>
            <input
              type="text"
              placeholder="Vehicle Model"
              name="model"
              value={formDescription.model}
              onChange={handleChangeDescription}
              required
            />
            <div className="description-box-1">
            <p>Description</p>
            <div className="hoteltitle">
            <textarea
              type="text"
              placeholder="Description"
              name="description"
              value={formDescription.description}
              onChange={handleChangeDescription}
              required
            /></div></div>
            
            {/* <p>Now, set your PRICE</p>
            <span>Rs.</span>
            <input
              type="number"
              placeholder="Price per night"
              name="price"
              value={formDescription.price}
              onChange={handleChangeDescription}
              className="price"
              required
            /> */}
          </div></div>


            
            


            </div>
            <div className="create-activities_step2">
            <div className="step-heading">
  <div className="step-number">STEP:02</div>
  <div className="step-description">MAKE YOUR VEHICLE STANDS OUT</div>
  
</div>
            <hr />
            <div className="add-photos">
            
            <h3>BASIC DETAILS</h3>
            <div className="basic-deatils">
            <div className="vehicle-radio">
              <h4>Air Conditioning</h4>
                        <div className="option-select">
                            <label>
                                <input
                                    type="radio"
                                    name="airConditioning"
                                    value="yes"
                                    checked={airConditioning === "yes"}
                                    onChange={(e) => setAirConditioning(e.target.value)}
                                    required
                                />
                                Yes
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="airConditioning"
                                    value="no"
                                    checked={airConditioning === "no"}
                                    onChange={(e) => setAirConditioning(e.target.value)}
                                    required
                                />
                                No
                            </label>
                        </div>
            </div>
            <div className="vehicle-radio">
              <h4>Gear Type</h4>
                        <div className="option-select">
                            <label>
                                <input
                                    type="radio"
                                    name="gearType"
                                    value="automatic"
                                    checked={gearType === "automatic"}
                                    onChange={(e) => setGearType(e.target.value)}
                                    required
                                />
                                Automatic
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="gearType"
                                    value="manual"
                                    checked={gearType === "manual"}
                                    onChange={(e) => setGearType(e.target.value)}
                                    required
                                />
                                Manual
                            </label>
                        </div>
              </div> 
              <div className="vehicle-radio">
              <h4>Driver</h4>
                        <div className="option-select">
                            <label>
                                <input
                                    type="radio"
                                    name="driver"
                                    value="withdriver"
                                    checked={driver === "withdriver"}
                                    onChange={(e) => setDriver(e.target.value)}
                                    required
                                />
                                With Driver
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="driver"
                                    value="withoutdriver"
                                    checked={driver === "withoutdriver"}
                                    onChange={(e) => setDriver(e.target.value)}
                                    required
                                />
                                Without Driver
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="driver"
                                    value="withorwithoutdriver"
                                    checked={driver === "withorwithoutdriver"}
                                    onChange={(e) => setDriver(e.target.value)}
                                    required
                                />
                                With or Without Driver
                            </label>
                        </div>
            </div></div>
               <div className="basics">    
              <div className="basic">
                <p>Seat Count</p>
                <div className="basic_count">
                  <RemoveCircleOutline
                    onClick={() => {
                      seatCount > 1 && setSeatCount(seatCount - 1);
                    }}
                    sx={{
                      fontSize: "25px",
                      cursor: "pointer",
                      "&:hover": { color: "gray" },
                    }}
                  />
                  <p>{seatCount}</p>
                  <AddCircleOutline
                    onClick={() => setSeatCount(seatCount + 1)}
                    sx={{
                      fontSize: "25px",
                      cursor: "pointer",
                      "&:hover": { color: "#0C4670" },
                    }}
                  />
                </div>
              </div>
              <div className="basic">
                <p>Door Count</p>
                <div className="basic_count">
                  <RemoveCircleOutline
                    onClick={() => {
                      doorCount > 1 && setDoorCount(doorCount - 1);
                    }}
                    sx={{
                      fontSize: "25px",
                      cursor: "pointer",
                      "&:hover": { color: "#0C4670" },
                    }}
                  />
                  <span>{doorCount}</span>
                  <AddCircleOutline
                    onClick={() => setDoorCount(doorCount + 1)}
                    sx={{
                      fontSize: "25px",
                      cursor: "pointer",
                      "&:hover": { color: "#0C4670" },
                    }}
                  />
                </div> 
            </div>
            </div>  
            </div>
            <div className="description-box">
            <h3>SELECT RELEVANT FEATURES OF YOUR VEHICLE</h3>
            <div className="extras">
              {vehifeatures?.map((item, index) => (
                <div
                  className={`extra ${
                    features.includes(item.name) ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectFeatures(item.name)}
                >
                  <p>{item.name}</p>
                </div>
              ))}  
          </div>

            <h3>EXTRAS<span> (if you don't have select : None)</span></h3>
            <div className="extras">
              {extras?.map((item, index) => (
                <div
                  className={`extra ${
                    amenities.includes(item.name) ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectAmenities(item.name)}
                >
                  <p>{item.name}</p>
                </div>
              ))}  
          </div></div>


          
          <div className="description-box">
               <h3>RENTAL DETAILS</h3>
                <div className="rental-details">
                            <table>
                                <tbody>
                                     <tr>
                                        <td>Rate</td>
                                        <td>
                                        <div className="input-group1">
                                            <input
                                                type="number"
                                                name="price"
                                                value={formDescription.price}
                                                  onChange={handleChangeDescription}
                                                required
                                                style={{ paddingLeft: '35px' }}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Daily Mileage Limit</td>
                                        <td>
                                        <div className="input-group2">
                                            <input
                                                type="number"
                                                name="mileage"
                                                value={formDescription.mileage}
                                                 onChange={handleChangeDescription}
                                                required
                                                style={{ paddingRight: '35px' }}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Extra Mileage Charge (per km)</td>
                                        <td>
                                        <div className="input-group3">
                                            <input
                                                type="number"
                                                name="extraCharge"
                                                value={formDescription.extraCharge}
                                                  onChange={handleChangeDescription}
                                                required
                                                style={{ paddingLeft: '35px' }}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Minimum rental period</td>
                                        <td>
                                        <div className="input-group4">
                                            <input
                                                type="number"
                                                name="minimumPeriod"
                                                value={formDescription.minimumPeriod}
                                                  onChange={handleChangeDescription}
                                                required
                                                style={{ paddingRight: '35px' }}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Maximum rental period</td>
                                        <td>
                                        <div className="input-group5">
                                            <input
                                                type="number"
                                                name="maximumPeriod"
                                                value={formDescription.maximumPeriod}
                                                  onChange={handleChangeDescription}
                                                required
                                                style={{ paddingRight: '35px' }}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                </div></div>
                <div className="description-box">
                        <h3>CONTACT NUMBER</h3>
                <div className="location">
                    <input
                         type="text"
                          placeholder="+94"
                           name="contactNumber"
                           value={contactNumber}
                           onChange={handleChangeContactNumber}
                          required
                            />
               </div>  </div>
            
          </div>
          <button className="submit_btn" type="submit" disabled={images.length < 5}>
            {listingId ? "Update Listing" : "PUBLISH YOUR PROPERTY"}
          </button>
        </form>
      </div>
  
        <Footer />
      </>
    );
  };

  export default CreateVehicleRent;
